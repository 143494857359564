import { Link } from "react-router-dom";
import { Fragment } from "react";
import Text from './Text';
import { ZoomImage, ZoomImageThumb, ZoomImageText } from "./ZoomImage";
import SkewedImage from "./SkewedImage";
import Image from "./Image";
import OpeningHours from "./OpeningHours";
import Prices from "./Prices";
import Map from "./Map";
import TextMap from "./TextMap";
import EventsList from "./EventsList";
import AudioPlayer from "./AudioPlayer";

import helpers from "../../helpers";

export default function ElementGroup(props) {
	const getElement = (item, index) => {
		let sizing = '';
		let sizingClass = '';

		let sizingDict = {
			'1/3': ' basis-full md:basis-1/3 md:max-w-1/3',
			'2/3': ' basis-full md:basis-2/3 md:max-w-2/3',
			'1/2': ' basis-full md:basis-1/2 md:max-w-1/2'
		}
		let opposizeSizingDict = {
			'2/3': ' basis-full md:basis-1/3 md:max-w-1/3',
			'1/3': ' basis-full md:basis-2/3 md:max-w-2/3',
			'1/2': ' basis-full md:basis-1/2 md:max-w-1/2'
		}
		let ret;

		if (item.sizing) {
			sizing = item.sizing;

			sizingClass = sizingDict[sizing] || '';
		}

		if (item.type == 'heading') {
			ret = <h2 key={item.id}>{item.data_content}</h2>
		}
		else if (item.type == 'leadtext') {
			ret = <Text key={item.id} className={'lead'+sizingClass} content={item.content} />
		}
		else if (item.type == 'text') {
			ret = <Text key={item.id} content={item.content} className={'lead'+sizingClass} />
		}
		else if (item.type == 'zoomimage') {
			let imageProps = {}
			try {
				imageProps = JSON.parse(item.data_content);
			}
			catch (e) {}

			ret = <div key={item.id} className={'py-10'+sizingClass}>
				{
					item.file_thumb && <ZoomImageThumb thumb={helpers.mediaUrl(item.file_thumb)}>
						<ZoomImage hotspots={imageProps.hotspots || null} 
							showHotspotsOnZoom={imageProps.showHotspotsOnZoom || false}
							disableIndicatior={false}
							className={'text-xl max-w-prose'} 
							thumb={item.file_small ? helpers.mediaUrl(item.file_small) : item.file} 
							src={helpers.mediaUrl(item.file)} />
					</ZoomImageThumb>
				}
				{
					!item.file_thumb && <ZoomImage hotspots={imageProps.hotspots || null} className={'text-xl max-w-prose'} thumb={item.file_small ? helpers.mediaUrl(item.file_small) : item.file} src={helpers.mediaUrl(item.file)} />
				}
				{
					item.content &&
					<div className="mt-5 text-sam_darkblue [.section-syning_&]:text-sam_accent text-lg md:text-xl ![.section-syning_&]:text-md" dangerouslySetInnerHTML={{__html: item.content}} />
				}
			</div>
		}
		else if (item.type == 'zoomimage_text') {
			let imageProps = {}
			try {
				imageProps = JSON.parse(item.data_content);
			}
			catch (e) {}

			ret = <ZoomImageText textSizing={sizingClass} 
				imageSizing={opposizeSizingDict[sizing]}
				textContent={item.content}
				imageText={imageProps.imageText} 
				hotspots={imageProps.hotspots || null} 
				thumb={item.file_small ? helpers.mediaUrl(item.file_small) : item.file} 
				src={helpers.mediaUrl(item.file)} />
		}
		else if (item.type == 'skewedimage') {
			ret = <SkewedImage className={sizingClass} key={item.id} src={item.file} />
		}
		else if (item.type == 'image') {
			ret = <div className={sizingClass} key={item.id}>
				<Image alt={item.data_content ? item.data_content.replace('alt:', '') : null} src={item.file} />
				{
					(item.data_content || item.content) &&
					<div className="mt-5 text-sam_darkblue [.section-syning_&]:text-sam_accent text-lg md:text-xl ![.section-syning_&]:text-md" dangerouslySetInnerHTML={{__html: item.content ? item.content :  item.data_content.substr(0, 4) != 'alt:' ? item.data_content : ''}} />
				}
			</div>
		}
		else if (item.type == 'openinghours') {
			ret = <OpeningHours key={item.id} currentLang={props.currentLang} className={sizingClass} />
		}
		else if (item.type == 'price') {
			ret = <Prices key={item.id} currentLang={props.currentLang} className={sizingClass} />
		}
		else if (item.type == 'events_list') {
			ret = <EventsList key={item.id} currentLang={props.currentLang} />;
		}
		else if (item.type == 'map') {
			ret = <div className={sizingClass} key={item.id}>
				<iframe title={window.l('Kort tengt efni kaflans')} frameBorder="0" width="100%" height="450" src={'//ginnungagap.arnastofnun.is/vefkort/embed.html#'+item.data_content}></iframe>
			</div>
		}
		else if (item.type == 'ui_map') {
			let mapData = {}
			try {
				mapData = JSON.parse(item.data_content);
			}
			catch (e) {}

			ret = <div className={sizingClass} key={index}>
				<Map drawLines={mapData.drawLines || false} 
					className={(mapData.className || '')} 
					baseMap={mapData.map || null} 
					center={mapData.center || [64.789192,-18.267307]}
					zoom={mapData.zoom || null}
					markers={mapData.markers || []}
					hideTooltips={mapData.defaultHideTooltips || false} />
				{
					mapData.caption &&
					<div className="mt-5 text-sam_accent">{mapData.caption}</div>
				}
			</div>
		}
		else if (item.type == 'ui_text_map') {
			let mapData = {}
			try {
				mapData = JSON.parse(item.data_content);
			}
			catch (e) {}

			ret = <TextMap key={index} drawLines={mapData.drawLines || false} 
				className={(mapData.className || null)+sizingClass} 
				mapCenter={mapData.center || null}
				zoom={mapData.zoom || null}
				baseMap={mapData.map || null} 
				markers={mapData.markers || []}
				hideTooltips={mapData.defaultHideTooltips || false}
				textContent={item.content} />
		}
		else if (item.type == 'audio') {
			ret = <AudioPlayer src={helpers.mediaUrl(item.file)} info={item.data_content} text={item.content} />
		}
		else {
			ret = <div key={item.id} className={'overflow-hidden'}>
				<pre>{JSON.stringify(item, null, 2)}</pre>
				{
					item.content && <div className="border p-2 !text-xs" dangerouslySetInnerHTML={{__html: item.content}} />
				}
			</div>
		}

		return ret
	}

	return props.groupData.map((elementData, index) => {
		let element = getElement(elementData, index);

		return element;
	});
}

