import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import config from './../config';
import iconDown from './../img/icon-down.png';

function AppMenu(props) {
	const [menuOpen, setMenuOpen] = useState(false);
	const [visibleMenuGroup, setVisibleMenuGroup] = useState(null);

	const location = useLocation();
	const ref = useRef();

	useEffect(() => {
		setMenuOpen(false);
		setVisibleMenuGroup(false);
	}, [location]);

	const clickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setMenuOpen(false);
		}
	}

	useEffect(() => {
		window.document.addEventListener("mousedown", (clickOutside));

		return () => {
			window.document.removeEventListener("mousedown", (clickOutside));
		}
	});

	useEffect(() => {
		if (menuOpen) {
			window.document.body.classList.add('menu-open');
		}
		else {
			window.document.body.classList.remove('menu-open');
		}
	}, [menuOpen])

	const langSwitch = {
		'is': 'en',
		'en': 'is'
	}

	return <div ref={ref} className="sticky top-0 z-[60]">
		<button aria-label={window.l('Click here to switch website language to English')} className="menu-lang fixed md:absolute top-10 right-24 md:right-40 z-50 text-2xl" onClick={() => {
			if (props.onLangChange) {
				props.onLangChange(langSwitch[props.currentLang])
			}
		}}>{langSwitch[props.currentLang].toUpperCase()}</button>

		<button aria-label={window.l('Smelltu hér til að opna valmynd')} className="fixed md:absolute top-10 right-4 md:right-20 justify-self-end block space-y-2 transition-transform hover:scale-110 z-[60] pr-4"
			onClick={() => setMenuOpen(!menuOpen)}
		>
			<div className="menu-hamburger transition-all w-8 h-0.5"></div>
			<div className="menu-hamburger transition-all w-8 h-0.5 opacity-1"></div>
			<div className="menu-hamburger transition-all w-8 h-0.5"></div>
		</button>

		<div className="fixed top-0 right-0 bottom-0 left-0 overflow-hidden pointer-events-none">
			<div className={'absolute top-0 right-0 bottom-0 w-[500px] max-w-[100vw] pointer-events-auto z-50 transition-transform duration-500'+(menuOpen ? ' translate-x-0' : ' translate-x-[125%]')}>

				<div className={'absolute top-0 -left-20 right-0 bottom-0 bg-sam_darkblue shadow-xl transition-transform duration-1000 origin-top-left'+(menuOpen ? ' skew-x-6' : ' skew-x-0')}></div>

				<div className={'app-menu-list absolute top-0 pt-28 md:pt-40 left-10 md:left-20 right-0 pr-10 md:pr-20 bottom-0 text-left text-3xl text-white overflow-y-auto'}>
					<ul>
						<li>
							<NavLink activeClassName="underline" className={({ isActive, isPending }) => isActive ? 'text-sam_accent block p-2 hover:text-sam_red_light decoration-1' : 'block p-2 hover:text-sam_red_light decoration-1'} to={'/'}>{window.l('Forsíða')}</NavLink>
							<ul className="ml-5">
								{
									props.frontPageData && props.frontPageData.map((item, index) => <li key={index}>
										<NavLink className={'block p-2 !text-2xl hover:text-sam_red_light decoration-1'} to={'/#'+item.ref_name}>{item['name_'+props.currentLang]}</NavLink>
									</li>)
								}
							</ul>
						</li>
						<li>
							<NavLink activeClassName="underline" className={({ isActive, isPending }) => isActive ? 'text-sam_accent block p-2 hover:text-sam_red_light decoration-1' : 'block p-2 hover:text-sam_red_light decoration-1'} to={'/handrit'}>{window.l('Handritin á sýningunni')}</NavLink>
						</li>
						{
							props.currentLang != 'en' &&
							<li>
								<NavLink activeClassName="underline" className={({ isActive, isPending }) => isActive ? 'text-sam_accent block p-2 hover:text-sam_red_light decoration-1' : 'block p-2 hover:text-sam_red_light decoration-1'} to={'/'+config.exhibitionPageRoot}>{window.l('Viltu vita meira?')}</NavLink>
							</li>
						}
						{
							props.currentLang != 'en' &&
							<li>
								<ul className="ml-5">
									{
										props.siteData && props.siteData.map((item, index) => <li key={index}>
											<div>
												<button className="float-right" onClick={() => setVisibleMenuGroup(item.path == visibleMenuGroup ? null : item.path)}><img aria-hidden="true" className={'w-[32px] transition-all duration-[600ms]'+(item.path == visibleMenuGroup ? ' rotate-180' : '')} src={iconDown} /></button>
												<NavLink activeClassName="underline" className={({ isActive, isPending }) => isActive ? 'text-sam_accent block p-2 !text-2xl hover:text-sam_red_light decoration-1' : 'block p-2 !text-2xl hover:text-sam_red_light decoration-1'} to={config.exhibitionPageRoot+'/'+item.path}>{item['title_'+props.currentLang]}</NavLink>
											</div>
											<ul className={'ml-5 overflow-hidden transition-all duration-[600ms]'+(item.path == visibleMenuGroup || location.pathname.indexOf(config.exhibitionPageRoot+'/'+item.path) > -1 ? ' max-h-[500px]' : ' max-h-[0px]')}>
												{
													item.pages.map((page, pageIndex) => <li key={pageIndex}>
														<NavLink  activeClassName="underline"className={({ isActive, isPending }) => isActive ? 'text-sam_accent block p-2 !text-xl hover:text-sam_red_light decoration-1' : 'block p-2 !text-xl hover:text-sam_red_light decoration-1'} to={config.exhibitionPageRoot+'/'+item.path+'/'+page.path}>{page['title_'+props.currentLang]}</NavLink>
													</li>)
												}
											</ul>
										</li>)
									}
								</ul>
							</li>
						}
					</ul>
					<div className="border-t border-t-white/10 mt-8 p-8 text-lg">
						Heimur í orðum<br/>
						Arngrímsgötu 5<br/>
						107 Reykjavík<br/><br/>
						{window.l('Símanúmer')}: <a href="tel:+3548338451">833 8451</a><br/>
						<a href="mailto:thjonustuver@arnastofnun.is">thjonustuver@arnastofnun.is</a>
					</div>
				</div>
		
			</div>
		</div>
	</div>
}

export default AppMenu;