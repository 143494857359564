import { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import config from "../../config";
import { Evented } from "leaflet";

export default function EventsList(props) {
	const [events, setEvents] = useState(null);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const eventElement = useRef();

	useEffect(() => {
		if (!events) {
			fetch(config.apiRoot+'events')
				.then(res => res.json())
				.then(json => {
					
					setEvents(json.results);
				});
		}
	}, []);

	const formatDate = (item, includeYear) => {
		let lang = props.currentLang || 'is';
		const months = {
			is: [
				'',
				'janúar',
				'febrúar',
				'mars',
				'apríl',
				'maí',
				'júní',
				'júlí',
				'ágúst',
				'september',
				'október',
				'nóvember',
				'desember'
			],
			en: [
				'',
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			]
		};

		let dateFrom = item.date_from.split('-');
		let dateTo = item.date_to ? item.date_to.split('-') : null;

		const intStr = (i) => parseInt(i).toString();

		return dateTo ? (
			dateFrom[1] == dateTo[1] ? 
				intStr(dateFrom[2])+'.–'+intStr(dateTo[2])+'. '+months[lang][parseInt(dateFrom[1])]+(includeYear ? ' '+dateFrom[0] : '') : 
				intStr(dateFrom[2])+'. '+months[lang][parseInt(dateFrom[1])]+'–'+intStr(dateTo[2])+'. '+months[lang][parseInt(dateTo[1])]+(includeYear ? ' '+dateTo[0] : '')
		) : intStr(dateFrom[2])+'. '+months[lang][parseInt(dateFrom[1])]+(includeYear ? ' '+dateFrom[0] : '');
	}

	const formatTime = (item) => {
		let timeFrom = item.time_from.split(':');
		let timeTo = item.time_to ? item.time_to.split(':') : null;

		return timeFrom[0]+(parseInt(timeFrom[1]) > 0 ? '.'+timeFrom[1] : '')+(timeTo ? '–'+timeTo[0]+(parseInt(timeTo[1]) > 0 ? '.'+timeTo[1] : '') : '');
	}

	const getWindowWidth = () => {
		return Math.max(
			window.document.body.scrollWidth,
			window.document.documentElement.scrollWidth,
			window.document.body.offsetWidth,
			window.document.documentElement.offsetWidth,
			window.document.documentElement.clientWidth
		);
	}

	let visibleEvents = events ? events.filter(event => event['title_'+props.currentLang]) : null;

	return <div>
		<div className={'grid gap-4 my-10 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4'}>
			{
				visibleEvents && visibleEvents.map((item, index) => <Link key={index} onClick={(event) => {
					event.preventDefault();
					if (item['info_'+props.currentLang] && item['info_'+props.currentLang].length > 0) {
						setSelectedEvent(item);
						
						setTimeout(() => {
							if (eventElement.current && getWindowWidth() < 1024) {
								eventElement.current.scrollIntoView({
									behavior: 'smooth'
								});
							}
						}, 500);
					}
					else {
						setSelectedEvent(null);
					}
				}} className={'hover:no-underline rounded-sm transition-all '+(selectedEvent && selectedEvent.id == item.id ? ' bg-sam_darkblue text-white' : ' bg-sam_darkblue/15')+(item['info_'+props.currentLang] && item['info_'+props.currentLang].length > 0 ? ' hover:scale-105' : ' !cursor-default')}>
					<div className="p-4">
						<h3 className={'text-sam_darkblue transition-all'+(selectedEvent && selectedEvent.id == item.id ? ' text-white' : '')}>{item['title_'+props.currentLang]}</h3>
						<div className={'text-sam_red text-lg pb-2 transition-all'+(selectedEvent && selectedEvent.id == item.id ? ' text-white' : '')}>{formatDate(item)+' | '+formatTime(item)}</div>
						<div className={'text-sam_darkblue text-lg transition-all'+(selectedEvent && selectedEvent.id == item.id ? ' text-white' : '')}>{item['info_short_'+props.currentLang]}</div>
					</div>
				</Link>)
			}
		</div>
		{
			selectedEvent && <div className="scroll-mt-10" ref={eventElement}>
				<h3>{selectedEvent['title_'+props.currentLang]}</h3>
				<div className="md:flex">
					<div className="basis-2/3">
						<p className="mt-0" dangerouslySetInnerHTML={{__html: selectedEvent['info_'+props.currentLang]}} />
					</div>
					<div className="basis-1/3">
						<div className="bg-sam_darkblue/10 p-10">
							<div className="text-3xl">{formatDate(selectedEvent)}</div>
							<div className="text-2xl my-2">{selectedEvent.date_from.split('-')[0]}</div>
							<div className="text-3xl">{formatTime(selectedEvent)}</div>
							{
								selectedEvent.url && <div className="pt-5 text-xl"><a href={selectedEvent.url}>{window.l('Nánari upplýsingar')}</a></div>
							}
						</div>
					</div>
				</div>
			</div>
		}
	</div>
}