import { Fragment } from "react";
import PlinthMenu from "./PlinthMenu";
import SearchBox from "./SearchBox";

export default function PlinthMenuWrapper(props) {
	return <Fragment>

		<div className="container relative z-40 [.menu-open_&]:pointer-events-none [.menu-open_&]:opacity-0 transition-opacity duration-[300ms]">
			<div className="inner-container relative">
				<div className="relative">
					<SearchBox className={'absolute right-0 top-[-130px] md:-top-[214px]'} currentLang={props.currentLang} siteData={props.siteData} />

					<p className="md:absolute -right-[20px] lg:right-0 top-[-130px] md:-top-[114px] md:max-w-[300px] lg:max-w-[400px] md:text-right text-lg lg:text-xl text-white md:text-white/60 transition-all md:hover:text-white">Viltu vita meira um handritin og efni sýningarinnar? Hér getur þú fræðst um goðin, sögur um ferðalög, hefndir og margt fleira.</p>

				</div>
			</div>
		</div>

		<PlinthMenu className={'mt-0 md:mt-[200px] xl:mt-[-60px] transition-[opacity,transform] duration-[300ms] '+(props.initialized ? ' opacity-100' : ' opacity-0')} 
			currentLang={props.currentLang} 
			windowPos={props.windowPos} 
			plinthMouseEnter={(event, item) => {
				if (props.plinthMouseEnter) {
					props.plinthMouseEnter(event, item);
				}
			}} 
			plinthMouseLeave={(event, item) => {
				if (props.plinthMouseLeave) {
					props.plinthMouseLeave(event, item);
				}
			}}
			plinthClick={(event, item) => {
				if (props.plinthClick) {
					props.plinthClick(event, item);
				}
			}} />
	</Fragment>
}