import { Fragment, useEffect, useState } from 'react';

function BokunButton(props) {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const bokunScript = document.createElement('script');
		bokunScript.src = 'https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=1a1bb6aa-1088-42df-a4ab-b4c60e654fcb';
		bokunScript.async = true;
		bokunScript.onload = () => setLoading(false);
		
		document.body.appendChild(bokunScript)
	}, []);

	return <button className={'bokunButton '+props.className} 
		disabled 
		id="bokun_8cc8f927_159e_4eba_adbe_e2f506b6da92" 
		data-src={'https://widgets.bokun.io/online-sales/1a1bb6aa-1088-42df-a4ab-b4c60e654fcb/experience-calendar/987550?lang='+props.lang} 
		data-testid="widget-book-button"
	>{props.label}</button>
}

export default BokunButton;